import React from 'react'
import './nav.css'
import {AiOutlineHome} from 'react-icons/ai'
import { SiAboutdotme } from "react-icons/si";
import { BiBook } from 'react-icons/bi'
import { IoIosContact } from "react-icons/io";

const nav = () => {
  return (
    <nav>
      <a href="#"><AiOutlineHome/></a>
      <a href="#about"><SiAboutdotme/></a>
      <a href="#experience"><BiBook/></a>
      <a href="#contact"><IoIosContact/></a>
    </nav>
  )
}

export default nav