import React from 'react'
import {BsLinkedin} from 'react-icons/bs'
import { FaGithubSquare } from 'react-icons/fa';


const HeaderSocials = () => {
  return (
    <div className='header__socials'>
        <a href="https://github.com/AvaEnke" ><FaGithubSquare /></a>
        <a href="https://www.linkedin.com/in/avaenke/" ><BsLinkedin/></a>
    </div>
  )
}

export default HeaderSocials