import React from 'react'
import { useRef } from 'react';
import './contact.css'
import {MdOutlineEmail} from 'react-icons/md'
import emailjs from 'emailjs-com'


const Contact = () => {
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_x6px6ho', 'template_a6jlahj', form.current, 'Bjrf0HSfIVJnI_AWK')
   
      .then((result) => {
          console.log(result.text);
      }, (error) => {
          console.log(error.text);
      });
      e.target.reset();
  };

  return (
    <section id='contact'>
      <h5 style={{ marginTop: '-3rem', marginBottom: '0rem' }}>Get In touch</h5>
      <h2>Contact Me</h2>
      
      <div className="container contact__container">
        <div className='contact__options'>
          <article className="contact__option">
            <MdOutlineEmail className='contact__option-icon'/>
            <h4>Email</h4>
            <h5>avaenke@icloud.com</h5>
            <a href="mailto:avaenke@icloud.com">Send A Message</a>
          </article>
        </div>
        <form ref={form} onSubmit={sendEmail}>
      <label>Name</label>
      <input type="text" name="user_name" />
      <label>Email</label>
      <input type="email" name="user_email" />
      <label>Message</label>
      <textarea name="message" />
          <button type='submit' className='btn btn-primary'>Send Message</button>
        </form>
      </div>
    </section>
  )
}

export default Contact