import React from 'react'
import './about.css'
import ME from '../../assets/Personal website images/abme.jpeg'
import {FaAward} from 'react-icons/fa'
import {VscFolderLibrary} from 'react-icons/vsc'


const about = () => {
  return (
    <section id='about'>

      <h2 style={{ marginTop: '12rem', marginBottom: '2rem' }}>About Me</h2>
      <div className="container about__container">
        <div className="about__me">
          <div className="about__me-image">
            <img src={ME} alt="About Image" />
          </div>
        </div>

        <div className="about__content">
          <div className='about__cards'>
            <article className='about__card'>
              <FaAward className='about__icon'/>
              <h5>Experience</h5>
              <small>1 Year Working Experience</small>
            </article>

            <article className='about__card'>
              <VscFolderLibrary className='about__icon'/>
              <h5>Projects</h5>
            </article>
          </div>
          <p>As a computer science student at SLU, I am actively seeking a summer internship in backend development and devops. With a focus on promoting diversity and inclusion in tech, I am particularly interested in contributing to initiatives supporting women in tech. My goal is to leverage my driven work ethic and curiosity to make an immediate impact on business and development projects. Based in the Midwest, I am open to both onsite and remote internship opportunities in the CST or EST time zones. </p>
        </div>
      </div>

      </section>
  )
}

export default about