import React from 'react'
import './header.css'
import CTA from './CTA'
import HeaderSocial from './HeaderSocials'
import ME from '../../assets/Personal website images/hea.jpeg'

const header = () => {
  return (
    <header>
     <div className="container header__container">
      <h1 style={{ marginTop: '-3rem', marginBottom: '0rem' }}>Ava Enke</h1>
      <h5 className="text-light" style={{ marginBottom: '2rem' }}>Computer Science Student at Saint Louis University</h5>
        <CTA/>
        <HeaderSocial/>

        <div className="me">
          <img src={ME} alt="" className="image-border" />
        </div>
        
        <a href="#contact" className='scroll_down'>Scroll Down</a>
     </div>
    </header>
  )
}

export default header